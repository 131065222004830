import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "react-loader-spinner";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            units: [],

            unsubscribeUnits: [],
            reason: "",
            comments: "",

            emailChecked: false,

            formVisible: true,
            loaderVisible: false,

            lang: {
                title: "Rezygnacja z Newslettera",
                button: {
                    checkEmail: "Sprawdź Subskrypcję",
                    unsubscribe: "Zrezygnuj"
                },
                emailAddress: "Adres E-mail",
                enterEmail: "Wprowadź adres e-mail",
                comments: "Komentarze",
                selectReason: "Wybierz Powód",
                unsubscribeReasonHead:
                    "Wskaż dlaczego rezygnujesz z subskrypcji",
                yourComments:
                    "Twoje komentarze na temat naszego newslettera...",
                validation: {
                    validEmail:
                        "Poprawny format adresu e-mail: klient123@gmail.com"
                },
                unsubscribeReasons: [
                    "Zbyt często wysyłacie do mnie wiadomości",
                    "Przesyłane treści nie są dla mnie interesujące",
                    "Nigdy nie zapisywałem się na waszą listę mailingową",
                    "Inny powód"
                ],
                emptyList: "Twoja lista subskrypcji jest pusta!",
                backToMain: "Wróć",
                somethingWentWrong: "Coś poszło nie tak, spróbuj ponownie!",
                unsubscribedSuccess:
                    "Skutecznie zrezygnowałeś z subksrypcji naszego newslettera. Pamiętaj, że zawsze możesz do nas wrócić, wypełniając formularz na stronie. Do zobaczenia :)"
            }
        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitCheckSubscription = this.handleSubmitCheckSubscription.bind(
            this
        );
        this.handleSubmitUnsubscribe = this.handleSubmitUnsubscribe.bind(this);
        this.backToMain = this.backToMain.bind(this);
        this.setLangPl = this.setLangPl.bind(this);
        this.setLangEn = this.setLangEn.bind(this);
    }

    handleFieldChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleInputChange(event) {
        let units = this.state.unsubscribeUnits;

        if (!event.target.checked) {
            units = units.filter(function(value) {
                return value !== event.target.name;
            });
        } else {
            units.push(event.target.name);
        }

        this.setState({
            unsubscribeUnits: units
        });
    }

    handleSubmitCheckSubscription(event) {
        event.preventDefault();

        this.setState({
            formVisible: false,
            loaderVisible: true
        });

        const subscriber = {
            email: this.state.email
        };

        axios
            .post("/api/subscription/check", subscriber)
            .then(response => {
                if (response.status == 200) {
                    this.setState({
                        emailChecked: true,
                        units: response.data,
                        formVisible: true,
                        loaderVisible: false
                    });
                } else {
                    this.setState({
                        infoMessage: this.state.lang.somethingWentWrong,
                        loaderVisible: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    infoMessage: this.state.lang.somethingWentWrong,
                    loaderVisible: false
                });
            });
    }

    backToMain(event) {
        event.preventDefault();

        this.setState({
            email: "",
            units: [],

            unsubscribeUnits: [],
            reason: "",
            comments: "",

            infoMessage: "",

            emailChecked: false,

            formVisible: true,
            loaderVisible: false
        });
    }

    handleSubmitUnsubscribe(event) {
        event.preventDefault();

        this.setState({
            formVisible: false,
            loaderVisible: true
        });

        const subscriber = {
            email: this.state.email,
            units: this.state.unsubscribeUnits,
            reason: this.state.reason,
            comments: this.state.comments
        };

        axios
            .post("/api/subscription/unsubscribe", subscriber)
            .then(response => {
                if (response.status == 200) {
                    this.setState({
                        infoMessage: this.state.lang.unsubscribedSuccess,
                        loaderVisible: false
                    });
                } else {
                    console.log(response);
                    this.setState({
                        infoMessage: this.state.lang.somethingWentWrong,
                        loaderVisible: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    infoMessage: this.state.lang.somethingWentWrong,
                    loaderVisible: false
                });
            });
    }

    setLangPl() {
        this.setState({
            lang: {
                title: "Rezygnacja z Newslettera",
                button: {
                    checkEmail: "Sprawdź Subskrypcję",
                    unsubscribe: "Zrezygnuj"
                },
                emailAddress: "Adres E-mail",
                enterEmail: "Wprowadź adres e-mail",
                comments: "Komentarze",
                selectReason: "Wybierz Powód",
                unsubscribeReasonHead:
                    "Wskaż dlaczego rezygnujesz z subskrypcji",
                yourComments:
                    "Twoje komentarze na temat naszego newslettera...",
                validation: {
                    validEmail:
                        "Poprawny format adresu e-mail: klient123@gmail.com"
                },
                unsubscribeReasons: [
                    "Zbyt często wysyłacie do mnie wiadomości",
                    "Przesyłane treści nie są dla mnie interesujące",
                    "Nigdy nie zapisywałem się na waszą listę mailingową",
                    "Inny powód"
                ],
                emptyList: "Twoja lista subskrypcji jest pusta!",
                backToMain: "Wróć",
                somethingWentWrong: "Coś poszło nie tak, spróbuj ponownie!",
                unsubscribedSuccess:
                    "Skutecznie zrezygnowałeś z subksrypcji naszego newslettera. Pamiętaj, że zawsze możesz do nas wrócić, wypełniając formularz na stronie. Do zobaczenia :)"
            }
        });
    }

    setLangEn() {
        this.setState({
            lang: {
                title: "Unsubscribe Newsletter",
                button: {
                    checkEmail: "Check My Subscription",
                    unsubscribe: "Unsubscribe"
                },
                emailAddress: "E-mail address",
                enterEmail: "Enter your e-mail address",
                comments: "Comments",
                selectReason: "Select Reason",
                unsubscribeReasonHead:
                    "Please point, why give up your subscription",
                yourComments: "Your comments about our newsletter...",
                validation: {
                    validEmail:
                        "Valid e-mail is required: customer123@gmail.com"
                },
                unsubscribeReasons: [
                    "You send me too many messages",
                    "Submitted content is not interesting for me",
                    "I never signed in your newsletter",
                    "Other reason"
                ],
                emptyList: "Your subscription list is empty!",
                backToMain: "Go Back",
                somethingWentWrong: "Something went wrong, try again!",
                unsubscribedSuccess:
                    "You effectively unsubscribe from our newsletter! Remember that you can always come back to us by completing the form on our website. See you later :)"
            }
        });
    }

    render() {
        return (
            <div>
                <div className="center-content">
                    <button onClick={this.setLangPl} className="change-lang">
                        PL
                    </button>{" "}
                    /{" "}
                    <button onClick={this.setLangEn} className="change-lang">
                        EN
                    </button>
                </div>

                <span className="contact100-form-title">
                    {this.state.lang.title}
                </span>

                <div className="center-content">
                    <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.loaderVisible}
                    />
                </div>

                {!this.state.emailChecked && this.state.formVisible ? (
                    <form
                        className="contact100-form validate-form"
                        onSubmit={this.handleSubmitCheckSubscription}
                    >
                        <div
                            className="wrap-input100 validate-input"
                            data-validate={
                                this.state.lang.validation.validEmail
                            }
                        >
                            <span className="label-input100">
                                {this.state.lang.emailAddress}
                            </span>
                            <input
                                className="input100"
                                type="email"
                                name="email"
                                placeholder={this.state.lang.enterEmail}
                                value={this.state.email}
                                onChange={this.handleFieldChange}
                                required
                            />
                            <span className="focus-input100"></span>
                        </div>
                        <div className="container-contact100-form-btn">
                            <div className="wrap-contact100-form-btn">
                                <div className="contact100-form-bgbtn"></div>
                                <button
                                    type="submit"
                                    className="contact100-form-btn"
                                >
                                    <span>
                                        {this.state.lang.button.checkEmail}{" "}
                                        <i
                                            className="fa fa-long-arrow-right m-l-7"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    ""
                )}

                {this.state.emailChecked && this.state.formVisible ? (
                    this.state.units.length > 0 ? (
                        <form
                            className="contact100-form validate-form"
                            onSubmit={this.handleSubmitUnsubscribe}
                        >
                            <ul className="list-group list-group-flush">
                                {this.state.units.map(unit => (
                                    <label key={unit.key}>
                                        <input
                                            name={unit.key}
                                            type="checkbox"
                                            onChange={this.handleInputChange}
                                        />{" "}
                                        {unit.name} ({unit.domain})
                                    </label>
                                ))}
                            </ul>
                            <div className="select2-container">
                                <div className="wrap-input100 input100-select">
                                    <span className="label-input100">
                                        {this.state.lang.unsubscribeReasonHead}
                                    </span>
                                    <div>
                                        <select
                                            className="selection-2"
                                            name="reason"
                                            onChange={this.handleFieldChange}
                                        >
                                            <option key="default" value="">
                                                {this.state.lang.selectReason}
                                            </option>
                                            {this.state.lang.unsubscribeReasons.map(
                                                reason => (
                                                    <option
                                                        value={reason}
                                                        key={reason}
                                                    >
                                                        {reason}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <span className="focus-input100"></span>
                                </div>
                            </div>
                            <div className="wrap-input100 validate-input">
                                <span className="label-input100">
                                    {this.state.lang.comments}
                                </span>
                                <textarea
                                    className="input100"
                                    name="comments"
                                    placeholder={this.state.lang.yourComments}
                                    onChange={this.handleFieldChange}
                                ></textarea>
                                <span className="focus-input100"></span>
                            </div>
                            <div className="container-contact100-form-btn">
                                <div className="wrap-contact100-form-btn">
                                    <div className="contact100-form-bgbtn"></div>
                                    <button
                                        type="submit"
                                        className="contact100-form-btn"
                                    >
                                        <span>
                                            {this.state.lang.button.unsubscribe}{" "}
                                            <i
                                                className="fa fa-long-arrow-right m-l-7"
                                                aria-hidden="true"
                                            ></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className="center-content">
                            <p>
                                {this.state.lang.emptyList}
                                <br />
                                <button
                                    className="return-back"
                                    onClick={this.backToMain}
                                >
                                    <i
                                        className="fa fa-long-arrow-left m-l-7"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.lang.backToMain}
                                </button>
                            </p>
                        </div>
                    )
                ) : (
                    ""
                )}

                {this.state.infoMessage ? (
                    <div className="center-content">
                        <p>{this.state.infoMessage}</p>
                        <button
                            className="return-back"
                            onClick={this.backToMain}
                        >
                            <i
                                className="fa fa-long-arrow-left m-l-7"
                                aria-hidden="true"
                            ></i>{" "}
                            {this.state.lang.backToMain}
                        </button>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

ReactDOM.render(<App />, document.getElementById("app"));
